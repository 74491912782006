//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TriangleCustomRate from '$resources/images/triangle-custom-rate.png';

export default {
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE').format(Number(value).toFixed(2));
    }
  },
  props: {
    selected: Boolean,
    offer: Object,
    hiredPower: String,
    variant: {
      type: String,
      validator: v => ['common', 'without-wattage'].includes(v),
      default: 'common'
    }
  },
  data: () => ({
    TriangleCustomRate
  }),
  computed: {
    ratesDescription() {
      switch (this.offer.idTarifaPersonalizada) {
        case '142':
          return [
            'Paga lo que consumes.',
            'Sin permanencias ni penalización por cancelación avisando 2 meses antes.',
            'Sácale partido a los tramos más baratos.',
            'Nuestro margen: 0,02€/kWh.',
            'Ten el control de tus consumos a través de nuestra APP.'
          ];
        case '246':
          return [
            'Paga lo que consumes.',
            'Sin permanencias ni penalización por cancelación.',
            'Sácale partido a los tramos más baratos.',
            'Nuestro margen: 0,015€/kWh.',
            'Ten el control de tus consumos a través de nuestra APP.'
          ];
        case '251':
          return [
            'Paga lo que consumes.',
            'Sin permanencias ni penalización por cancelación.',
            'Sácale partido a los tramos más baratos.',
            'Nuestro margen: 0,02€/kWh.',
            'Ten el control de tus consumos a través de nuestra APP.'
          ];
        case '262':
          return [
            'Paga lo que consumes.',
            'Sin permanencias ni penalización por cancelación avisando 2 meses antes.',
            'Sácale partido a los tramos más baratos.',
            'Nuestro margen: 0,01€/kWh.',
            'Ten el control de tus consumos a través de nuestra APP.'
          ];
        case '305':
        case '306':
          return [
            'Paga lo que consumes.',
            'Sácale partido a los tramos más baratos.',
            'Ten el control de tus consumos a través de nuestra APP.'
          ];
        default:
          return [];
      }
    },
    tarifaNameFormated() {
      if (this.offer.aliasTarifa) {
        return this.offer.aliasTarifa;
      }

      return this.offer.nombreTarifa;
    },
    priceMontlyClassName() {
      return {
        alone: this.offer.ahorroMensual === null,
        small: this.offer.precioMensual
      };
    },
    savingsMontlyClassName() {
      return {
        small: String(this.offer.ahorroMensual.toFixed(2)).length > 5
      };
    }
  }
};
